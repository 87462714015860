<template>
      <div class='praise flex-align-between'>
            <img :src="imgShow?img1:img2" @click="imgCut">
            <span :class="{num:imgShow}">{{num}}</span>
      </div>
</template>

<script>
      export default {
            data() {
                  return {
                        imgShow: false,
                        img1: require('../../../assets/myactivity/red.png'),
                        img2: require('../../../assets/myactivity/gray.png'),
                        num: 0,

                  }
            },
            props: ['praiseId', 'quantity', 'haveLike'],
            //方法
            methods: {
                  imgCut() {

                        this.imgShow = !this.imgShow;
                        if (this.imgShow) {
                              this.num = this.num + 1
                        } else {
                              this.num = this.num - 1;
                        }
                        this.changeLike()
                  },
                  async changeLike() {
                        let resData = await this.$Api.Myactivity.changeLike(this.praiseId);
                  }
            },

            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.haveLike == 1 ? this.imgShow = true : this.imgShow = false;
                  this.num = this.quantity;
            }
      }
</script>
<style lang='less' scoped>
      @import "./less/praise";
</style>