<template>
      <div class='sample flex-layout'>
            <div class="cutlist">
                  <div @click="workShow = true" :class="{cutstyle:workShow}">上墙作品</div>
                  <div @click="workShow = false" :class="{cutstyle:!workShow}">学员作品</div>
            </div>
            <div class="workBox">
                  <div class="workSel" v-if="category == 'activity'">
                        <div class="workSeltext">选择班级：</div>
                        <div class="sel1 sel">
                              <vxe-select v-model="value1" placeholder="默认尺寸" @change='classChange'>
                                    <vxe-option v-for="(item,index) in classData" :key="index" :value="item.name"
                                          :label="item.name">
                                    </vxe-option>
                              </vxe-select>
                        </div>
                        <div class="sel2 sel" v-if="workShow">
                              <vxe-select v-model="value2" placeholder="默认尺寸" @change='dateChange'>
                                    <vxe-option v-for="(item,index) in date" :key="index" :value="item.name"
                                          :label="item.name">
                                    </vxe-option>
                              </vxe-select>
                        </div>
                  </div>
                  <div class="workSel" v-else>
                        <div class="workSeltext">选择班级：</div>
                        <div class="sel1 sel">
                              <vxe-select v-model="value1" placeholder="默认尺寸" @change='classChange'>
                                    <vxe-option v-for="(item,index) in classData" :key="index" :value="item.name"
                                          :label="item.name">
                                    </vxe-option>
                              </vxe-select>
                        </div>
                        <div class="workSeltext">选择活动：</div>
                        <div class="sel2 sel">
                              <vxe-select v-model="value3" placeholder="默认尺寸" @change='activityChange'>
                                    <vxe-option v-for="(item,index) in activityList" :key="index" :value="item.title"
                                          :label="item.title">
                                    </vxe-option>
                              </vxe-select>
                        </div>
                  </div>
                  <div class="workBoxList" v-if="workShow">
                        <div class="workBoxBg">
                              <ul class="flex-align-wrap" v-if="dataArr.records.length !=0">
                                    <li class="flex-column" v-for="(item,index) in dataArr.records" :key="index">
                                          <div class="info">
                                                <div class="flex-align-between">
                                                      <img
                                                            :src="item.avatar?'https://test.lezhireading.com/uploads/'+item.avatar:head">
                                                      <span>{{item.userName}}</span>
                                                </div>
                                                <span>{{item.totalScore}}分</span>
                                          </div>
                                          <div class="imge flex-align-center">
                                                <!-- :preview-src-list="item.srcList" -->
                                                <el-image :src="item.work" :preview-src-list="item.srcList">
                                                </el-image>
                                          </div>
                                          <div class="time flex-align-between">
                                                <span>{{item.createTime}}</span>
                                                <Praise :praiseId='item.id' :quantity='item.totalLike'
                                                      :haveLike='item.haveLike'></Praise>
                                          </div>
                                          <div class="triangle"
                                                v-if="item.score>=8&&(item.isValid==2 || item.isSetValid == 1)">
                                                <img src="../../../assets/myactivity/appear.png">
                                          </div>
                                    </li>
                              </ul>
                              <div class="empty flex-column-center" v-else>
                                    <img src="../../../assets/empty.png">
                                    <span>期待同学们上传作品~</span>
                              </div>
                        </div>

                        <div class="pag flex-align-center">
                              <vxe-pager background :current-page.sync="page1.currentPage"
                                    :page-size.sync="page1.pageSize" :total="page1.totalResult"
                                    :layouts="['PrevPage', 'JumpNumber', 'NextPage','Total', 'FullJump']"
                                    @page-change="page1change">
                              </vxe-pager>
                        </div>
                  </div>
                  <div class="student flex-between" v-else>
                        <div class="crew " v-if="dataArrName1.records.length !=0">
                              <div>
                                    <div class="imgbox imgbox1 flex-align-center">
                                          <img class="imgbox1" src="../../../assets/myactivity/bottom.png"
                                                @click="slidePre" v-show="studentpage !=1">
                                    </div>
                                    <div class="studentLsit ">
                                          <ul class="">
                                                <li v-for="(item,index) in dataArrName1.records" :key="index"
                                                      :class="{nameStyle:nameIndex == index}"
                                                      @click="Namechange(index,item.userId)">
                                                      {{item.userName}}
                                                </li>
                                          </ul>
                                    </div>
                                    <div class="imgbox flex-align-center">
                                          <img class="imgbox2" src="../../../assets/myactivity/top.png"
                                                @click="slideNext" v-show="studentpage !=stupagesum">
                                    </div>
                              </div>
                              <!-- <div v-else class="placeholder flex-align-center">
                                    期待同学们上传作品~
                              </div> -->

                        </div>
                        <div class="workBoxList workBoxList2" :class="{addMargin: dataArrName1.records.length == 0}">
                              <div class="workBoxBg">
                                    <ul class="flex-align-wrap" v-if="dataArrs.records.length !=0">
                                          <li class="flex-column" v-for="(item,index) in dataArrs.records" :key="index">
                                                <div class="imge flex-align-center">
                                                      <el-image :src="item.work" :preview-src-list="item.srcList">
                                                      </el-image>
                                                </div>
                                                <div class="time flex-align-between">
                                                      <span>{{item.createTime}}</span>
                                                      <Praise :praiseId='item.id' :quantity='item.totalLike'
                                                            :haveLike='item.haveLike'></Praise>
                                                </div>

                                                <div class="triangle"
                                                      v-if="item.score>=8&&(item.isValid==2 || item.isSetValid == 1)">
                                                      <img src="../../../assets/myactivity/appear.png">
                                                </div>
                                          </li>
                                    </ul>
                                    <div class="empty flex-column-center" v-else>
                                          <img src="../../../assets/empty.png">
                                          <span>期待同学们上传作品~</span>
                                    </div>
                              </div>

                              <div class="pag flex-align-center">
                                    <vxe-pager background :current-page.sync="page2.currentPage"
                                          :page-size.sync="page2.pageSize" :total="page2.totalResult"
                                          :layouts="['PrevPage', 'JumpNumber', 'NextPage','Total', 'FullJump']"
                                          @page-change="page2change">
                                    </vxe-pager>
                              </div>
                        </div>
                  </div>

            </div>
      </div>
</template>

<script>
      import Praise from './praise';
      import {
            slider,
            slideritem
      } from "vue-concise-slider";

      export default {
            data() {
                  return {
                        value1: '请选择',
                        value2: '请选择',
                        value3: '请选择',
                        workShow: true,
                        nameIndex: 0,

                        upShow: false,
                        downShow: true,
                        //学员列表
                        dataArrName1: {
                              pages: 1,
                              size: 8,
                              total: 0,
                              records: [],
                        },
                        // 上墙作品
                        dataArr: {
                              pages: 1,
                              size: 8,
                              total: 0,
                              records: [],
                              srcList: [],
                        },
                        //学员作品
                        dataArrs: {
                              pages: 1,
                              size: 8,
                              total: 0,
                              records: [],
                              srcList: [],
                        },


                        classData: [],
                        date: [{
                                    name: '一周内',
                                    type: 1
                              },
                              {
                                    name: '一月内',
                                    type: 2
                              },
                              {
                                    name: '一年内',
                                    type: 3
                              },
                        ],

                        page1: {
                              currentPage: 1,
                              pageSize: 8,
                              totalResult: 0
                        },
                        page2: {
                              currentPage: 1,
                              pageSize: 8,
                              totalResult: 0
                        },
                        classpage: 1,
                        classsize: 8,
                        stuClasspage: 1,
                        stuClasssize: 8,
                        studentpage: 1,
                        studentsize: 8,
                        stupagesum: 0,

                        classId: '',
                        dateType: '',
                        userId: '',
                        activityList: [],
                        activity: '',
                        head: require("../../../assets/myactivity/stu-boy.png"),
                  }
            },
            components: {
                  slider,
                  slideritem,
                  Praise
            },
            props: ['activityId', 'category'],
            //方法
            methods: {

                  slidePre() {
                        if (this.studentpage <= 1) return false;
                        this.studentpage--;
                        this.nameIndex = 0;
                        this.getqueryClassUsers();
                  },
                  slideNext() {

                        if (this.studentpage >= this.stupagesum) return false;
                        this.studentpage++;
                        this.nameIndex = 0;
                        this.getqueryClassUsers();
                  },
                  //名字筛选
                  Namechange(index, id) {
                        this.nameIndex = index;
                        this.userId = id;

                        this.getClassUpWorks2();
                  },
                  async getClass() {
                        let resData = await this.$Api.Myactivity.getqueryTeacherClass();
                        this.classData = resData.data;
                        this.value1 = this.classData[0].name;
                        this.classId = this.classData[0].id;
                        if (this.category == 'activity') {
                              this.activity = this.activityId;
                              this.value2 = this.date[0].name;
                              this.dateType = this.date[0].type;
                              this.getClassUpWorks();
                              this.getqueryClassUsers();
                              //活动
                        } else {
                              this.getselectByClass();
                              this.getqueryClassUsers();
                              //作品集
                        }
                  },
                  //获取作品1
                  async getClassUpWorks() {
                        this.page1 = {
                              currentPage: 1,
                              pageSize: 8,
                              totalResult: 0
                        };
                        let data = {
                              page: this.classpage,
                              size: this.classsize,
                              classId: this.classId,
                              activityId: this.activity,
                              type: this.dateType,
                              flag: true,
                        }
                        let resData = await this.$Api.Myactivity.getClassUpWorks(data);
                        this.dataArr = resData.data;
                        this.page1.currentPage = this.dataArr.current;
                        this.page1.pageSize = this.dataArr.size;
                        this.page1.totalResult = this.dataArr.total;
                        for (let i = 0; i < this.dataArr.records.length; i++) {
                              this.$set(this.dataArr.records[i], 'srcList', [
                                    this.dataArr.records[i].work
                              ])

                        }


                  },
                  //学员作品
                  async getClassUpWorks2() {
                        this.page2 = {
                              currentPage: 1,
                              pageSize: 8,
                              totalResult: 0
                        };
                        let data = {
                              page: this.stuClasspage,
                              size: this.stuClasssize,
                              classId: this.classId,
                              userId: this.userId,
                              activityId: this.activity,
                              type: this.dateType,

                        }
                        let resData = await this.$Api.Myactivity.getClassUpWorks(data);
                        this.dataArrs = resData.data;
                        this.page2.currentPage = this.dataArrs.current;
                        this.page2.pageSize = this.dataArrs.size;
                        this.page2.totalResult = this.dataArrs.total;
                        for (let i = 0; i < this.dataArrs.records.length; i++) {
                              this.$set(this.dataArrs.records[i], 'srcList', [
                                    this.dataArrs.records[i].work
                              ])

                        }
                  },
                  //报名学生
                  async getqueryClassUsers() {
                        let data = {
                              classId: this.classId,
                              activityId: this.activity,
                              page: this.studentpage,
                              size: this.studentsize,
                        }
                        let resData = await this.$Api.Myactivity.getqueryClassUsers(data);
                        this.dataArrName1 = resData.data;
                        if (this.dataArrName1.records.length == 0) return false;
                        this.stupagesum = this.dataArrName1.pages;
                        this.userId = this.dataArrName1.records[0].userId;
                        this.getClassUpWorks2()
                  },
                  //活动
                  async getselectByClass() {
                        let resData = await this.$Api.Myactivity.getselectByClass(this.classId);
                        this.activityList = resData.data;
                        if (this.activityList.length == 0) return false;
                        this.activity = this.activityList[0].id;
                        this.value3 = this.activityList[0].title;
                        this.getClassUpWorks();
                        this.getqueryClassUsers();
                  },


                  // 班级筛选
                  classChange(value) {
                        this.classId = this.classData[this.IndexEvent(value, this.classData)].id;
                        //作品集合
                        this.activityList = [];
                        this.value3 = '请选择';
                        this.nameIndex = 0;
                        //作品集合
                        this.dataArrs = {
                              pages: 1,
                              size: 10,
                              total: 8,
                              records: [],
                        }
                        this.dataArrName1 = {
                              pages: 1,
                              size: 10,
                              total: 8,
                              records: [],
                        };
                        this.dataArr = {
                              pages: 1,
                              size: 10,
                              total: 8,
                              records: [],
                        }
                        if (this.category == 'activity') {
                              this.value2 = this.date[0].name;
                              this.dateType = this.date[0].type;
                              this.getClassUpWorks();
                              this.getqueryClassUsers();

                        } else {
                              this.getselectByClass();
                        }
                  },
                  // 时间筛选
                  dateChange(value) {
                        this.dateType = this.date[this.IndexEvent(value, this.date)].type;
                        this.getClassUpWorks();
                  },
                  //活动筛选
                  activityChange(value) {
                        this.activity = this.activityList[this.IndexEvent(value, this.activityList)].id;
                        this.nameIndex = 0;
                        this.getClassUpWorks();
                        this.getqueryClassUsers();
                  },
                  //分页
                  page1change(currentPage, pageSize) {
                        this.classpage = currentPage.currentPage;
                        this.getClassUpWorks();
                  },
                  page2change(currentPage, pageSize) {
                        this.stuClasspage = currentPage.currentPage;
                        this.getClassUpWorks2();
                  },
                  //下拉
                  IndexEvent(value, data) {
                        let index = '';
                        return index = data.findIndex(item => {
                              return value.value === item.name || value.value === item.title;
                        });
                  }

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.getClass();
                  // this.getqueryClassUpWorks()
            }
      }
</script>
<style lang='less' scoped>
      @import "./less/sample";
</style>
